var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"mb-6 pa-2"},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row mb-6"},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Rechercher une date","dense":""},on:{"input":_vm.search},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Rechercher une colonne","dense":""},on:{"input":_vm.search},model:{value:(_vm.searchColStr),callback:function ($$v) {_vm.searchColStr=$$v},expression:"searchColStr"}}),_c('v-spacer'),_c('v-autocomplete',{attrs:{"items":_vm.sitesList,"menu-props":{ maxHeight: '400', zIndex: 13 },"label":"Sélectionner un site","item-text":"name","item-value":"id","dense":"dense","useGetParams":true},on:{"change":_vm.changeSite},model:{value:(_vm.selectedSiteId),callback:function ($$v) {_vm.selectedSiteId=$$v},expression:"selectedSiteId"}}),_c('date-picker-single-month',{attrs:{"identifier":"monthFilter","label":"Sélectionner un mois","dateInit":"yesterday","dense":"dense","customClass":"ml-4","hint":"","useGetParams":true},on:{"onSelect":_vm.setSelectedMonth}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.updateCacheIsShowed),expression:"updateCacheIsShowed"}],staticClass:"mx-1",attrs:{"outlined":"","dense":""},on:{"click":function($event){return _vm.debouncedGetData()}}},[_vm._v(" Reload ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.updateCacheIsShowed),expression:"updateCacheIsShowed"}],staticClass:"mx-1",attrs:{"outlined":"","dense":""},on:{"click":function($event){_vm.updateCache = true;
            _vm.debouncedGetData();}}},[_vm._v(" No cache ")]),_c('dot-menu',{attrs:{"csvButton":{
            csvData: _vm.csvData,
            filename: ("Prog-Inventory-month-" + (this.monthSelected) + "-" + (this.selectedSiteCode) + ".csv"),
          },"excelButton":{
            excelData: _vm.excelData,
            filename: ("Prog-Inventory-month-" + (this.monthSelected) + "-" + (this.selectedSiteCode) + ".xls"),
            disabled: false,
          }},on:{"exportCsv":_vm.exportCSV,"exportExcel":_vm.exportExcel}})],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","height":5}}),(_vm.filteredData.length !== 0)?_c('div',[_c('v-simple-table',{staticClass:"table-inventory",attrs:{"dense":"","fixed-header":"","height":"500"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.filteredData[0]),function(value,key,index){return _c('th',{key:index,staticClass:"text-left",class:("field_" + key)},[_vm._v(" "+_vm._s(key)+" ")])}),0)]),_c('tbody',_vm._l((_vm.filteredData),function(item,i){return _c('tr',{key:i,class:i % 2 === 0 ? 'row-inventory-odd' : 'row-inventory-even'},_vm._l((item),function(value,key,index){return _c('td',{key:index,staticClass:"cell",class:("value_" + key)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayFormat(value))}})])}),0)}),0)]},proxy:true}],null,false,2117039542)})],1):_c('div',[_vm._v(" "+_vm._s(_vm.noDataMessage)+" ")])],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_vm._v("Options d'affichage")]),_c('v-card-text',[_c('v-row',{staticClass:"ma-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Afficher les grands nombres plus lisibles"},model:{value:(_vm.displayPrettyNumbers),callback:function ($$v) {_vm.displayPrettyNumbers=$$v},expression:"displayPrettyNumbers"}}),_c('v-switch',{attrs:{"label":"Afficher moins de décimales"},model:{value:(_vm.displayLessDecimals),callback:function ($$v) {_vm.displayLessDecimals=$$v},expression:"displayLessDecimals"}})],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row mb-6"},[_c('p',{staticClass:"text-2xl mb-6"},[_vm._v("Inventaire mensuel / Jour / Site")])])}]

export { render, staticRenderFns }